import { useCallback } from 'react';

import { useSelfHumanityScoreQuery } from '@query';

import { useAuth } from '@shared/common/providers/AuthProvider';
import {
  useAuthModal,
  useCompleteMagicIdModal,
} from '@shared/common/providers/GlobalModalsProvider';

import useUserWalletStatusFlags from './useWeb3/useUserWalletStatusFlags';

const useCheckRequiredSelfData = () => {
  const { isAuth, user } = useAuth();
  const { open: openAuthModal } = useAuthModal();
  const { open: openCompleteMagicIdModal } = useCompleteMagicIdModal();
  const { isEMVWalletConnected: hasEvmWallet } = useUserWalletStatusFlags();
  const { data: gitcoin, isPending: isHumanityVerificationLoading } = useSelfHumanityScoreQuery({
    enabled: isAuth,
  });
  const hasUsername = !!user?.attributes.name;
  const hasTonWallet = !!user?.wallets.find((wallet) => wallet.attributes.network === 'TON');
  const hasEmail = !!user?.attributes.email;
  const hasLinkedTelegram = !!user?.socials.find((social) => social.type === 'TELEGRAM');
  const hasFaceVerification = !!user?.attributes.faceCheckPassed;
  const hasGitcoinVerification = !!gitcoin?.gitCoin?.acceptable;
  const check = useCallback(
    ({ auth, email, evmWallet }: { auth?: boolean; email?: boolean; evmWallet?: boolean }) => {
      if (auth && !isAuth) {
        openAuthModal();

        return false;
      }

      const missingData: string[] = [];

      if (email && !hasEmail) {
        missingData.push('email');
      }

      if (evmWallet && !hasEvmWallet) {
        missingData.push('evm');
      }

      if (missingData.length) {
        openCompleteMagicIdModal({
          description: {
            // ...(missingData.includes('name') && { name: null }),
            ...(missingData.includes('email') && { email: null }),
            ...(missingData.includes('evm') && { wallets: { EVM: null } }),
          },
          success: false,
        });

        return false;
      }

      return true;
    },
    [isAuth, hasEmail, hasEvmWallet, openAuthModal, openCompleteMagicIdModal],
  );

  return {
    isAuth,
    hasEmail,
    hasEvmWallet,
    hasTonWallet,
    hasUsername,
    hasFaceVerification,
    hasGitcoinVerification,
    isHumanityVerificationLoading,
    hasLinkedTelegram,
    check,
  };
};

export default useCheckRequiredSelfData;
